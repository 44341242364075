import intlTelInput from 'intl-tel-input';

document.querySelectorAll('input.phone').forEach(function(el) {
    let iti = intlTelInput(el, {
        defaultCountry: 'be',
        nationalMode: false,
        preferredCountries: ['be', 'nl', 'de', 'fr'],
        responsiveDropdown: true,
        utilsScript: "/js/phone-utils.js"
    });
    el.onfocus = function() {
        if(iti.getNumber().length === 0) {
            el.value = '+' + iti.getSelectedCountryData().dialCode
        }
    }
    el.keyup = function() {
        if(iti.getNumber().length === 0) {
            el.value = '+' + iti.getSelectedCountryData().dialCode
        } else {
            el.value = iti.getNumber();
        }
    }
})
