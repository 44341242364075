window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import './validation';
import './onkeypress';
import './modal';
import './phone';
import './timer';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/collapse';

